import { NestedKeyOf } from 'utils/typescript';

export type TranslationKey = NestedKeyOf<typeof translationFr>;

const translationFr = {
    metadata: {
        description: 'La commande en ligne Innovorder',
    },
    add: 'Ajouter',
    back: 'Retour',
    order_closed: 'Commande en ligne fermée',
    open_at: 'Ouvre {{date}} à {{hours}}:{{minutes}}',
    open_tomorrow_at: 'Ouvre demain à {{hours}}:{{minutes}}',
    open_today_at: "Ouvre aujourd'hui à {{hours}}:{{minutes}}",
    rush_delay_badge: '🕒 {{rushDelay, string}} min',
    rush_delay:
        'Nos cuisiniers ont du pain sur la planche !<br>Votre temps d’attente est prolongé de : {{rushDelay}} min',
    payment: {
        title: 'Paiement',
        mode: 'Mode de paiement',
        crypted_data:
            'Toutes les coordonnées relatives au paiement sont stockées et cryptées par notre partenaire de paiement',
        bank_card: 'Carte bancaire',
        confirm: 'Confirmer',
        google_pay: 'Google Pay',
        apple_pay: 'Apple Pay',
        edenred: 'Edenred',
        paygreen: {
            label: 'Titre Restaurant',
            description: 'Bimpli, Pass Resto, Up Déjeuner, Swile, Restoflash',
        },
        choice: {
            luncheon_voucher: 'Paiement par carte Titre Restaurant',
        },
        luncheon_voucher: 'Carte Titre Restaurant',
        luncheon_voucher_errors: {
            cart_with_non_eligible_products: 'Panier non éligible au Titre Restaurant',
            maximum_daily_amount: 'Non éligible au-delà de 25 euros',
        },
        cash: 'Espèces',
        payment_later: 'Paiement en caisse',
    },
    edenred: {
        authentication: {
            title: 'Edenred',
            description:
                "Si la page d'authentification ne s'est pas ouverte, veuillez y accéder via le bouton d'accès ci-dessous :",
            auth_button_url: "Lien d'accès",
            success_message: 'Authentification réussie',
            error: 'Une erreur est survenue',
            there_is: 'Il reste',
            on_your_daily_balance: 'sur votre solde quotidien',
        },
    },
    card_information: 'Information carte',
    additional_user_information: 'Informations complémentaires',
    order_confirmation: 'Confirmation de commande',
    disconnected_warning:
        'Votre navigateur semble être hors connexion.<br/>Rafraichissez la page pour suivre votre commande !',
    order_loading: 'Confirmation de votre commande... Veuillez ne pas rafraîchir la page !',
    stripe_confirm: 'Envoi de votre commande... Veuillez ne pas rafraîchir la page !',
    order_follow_up: {
        route: 'Suivi de commande',
        order_confirm: 'Confirmation de votre commande... Veuillez ne pas rafraîchir la page !',
        title: 'En cours de préparation...',
        multi_order_date: ' - Commande passée à ',
        order_number: 'n°{{orderNumber}}',
        description: 'Conservez cette page ouverte pour suivre la préparation en temps réel.',
        explanations: {
            title_first_part: 'Comment récupérer',
            title_highlighted_part: 'votre commande',
            step_1: 'Ne fermez pas votre onglet pour suivre votre commande',
            step_2: 'Suivez attentivement l’évolution de sa préparation sur votre écran',
            step_3: 'C’est prêt !<br/>Allez récupérer votre commande au stand correspondant',
            understood: 'Compris !',
        },
    },
    confirm_and_pay: 'Confirmer et payer',
    confirm_and_pay_with_amount: 'Confirmer et payer • {{amount, string}}',
    ewallet: 'Ewallet',
    menu_home: 'Menu',
    menu_home_table_number: 'Table {{tableNumber, number}}',
    restaurants: {
        title: 'Restaurants',
        no_restaurants: 'Aucun restaurant disponible 😪',
        no_restaurant_error: 'Aucun restaurant n’est activé sur votre enseigne.',
        subtitle: {
            wrong_schedule:
                "Verifiez l'ouverture de vos restaurants dans la reception et le backoffice innovorder.",
            wrong_mode: 'Aucun restaurant compatible avec ce mode de consommation.',
            no_image: 'Aucun restaurant ne possède de vignette.',
        },
    },
    loading: 'Chargement',
    loading_menu_error: 'Un problème est arrivé lors du téléchargement du menu',
    empty_product_list: 'Cette catégorie ne contient pas encore de produits 😢',
    button_show_basket: 'Voir mon panier • {{amount, string}}',
    button_pay: 'Payer • {{amount, string}}',
    button_next: 'Suivant',
    product_name_route: 'Produit',
    product_additional_information: 'Informations supplémentaires',
    total: 'Total',
    add_to_cart_with_price: 'Ajouter au panier • {{amount, string}}',
    product_doesnt_exist: "Désolé, ce produit n'existe malheureusement plus 😢",
    error_title: 'Oops...',
    qrcode_error: 'Mode food court désactivé. Scannez à nouveau le QR code.',
    no_consumption_mode: 'Aucun moyen de consommation activé pour ce restaurant',
    not_found_message: 'Désolé, votre page est introuvable.',
    not_found_back_to_home: 'Revenir à l’accueil',
    cart: {
        title: 'Panier',
        order: 'Ma commande',
        empty: 'Votre panier est vide 😪',
        empty_subtitle: 'Cliquez sur le bouton retour au menu pour completer votre menu',
        empty_cta: 'Retour au menu',
        stepper: {
            cart: 'Panier',
            name: 'Prénom',
            payment: 'Paiement',
            preparation: 'Préparation',
            summary: 'Récapitulatif',
        },
        mode: {
            title: 'Mode de consommation',
            sit_in: 'Sur place',
            take_away: 'A emporter',
            modal: {
                title: 'Vous allez changer de <strong>mode de consommation</strong>.',
                subtitle: 'Votre <strong>panier</strong> va être vidé.',
                question: 'Souhaitez-vous poursuivre ?',
                close: 'Non merci',
                action: 'Poursuivre',
            },
        },
        discount: {
            title: 'Promotion',
            add: 'Ajouter un code promo',
            validate: 'Ajouter',
        },
    },
    user_information: {
        title: 'Renseignez votre prénom ✍️',
        name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        name_description: 'Pour récupérer votre commande',
        mandatory: '*',
        input_placeholder: 'Jeanne',
    },
    price_break_down: {
        title: 'Résumé',
        subtotal: 'Sous-total',
        taxes: 'TVA',
        total: 'Total',
        discount: 'Promotion "{{ name }}"',
    },
    go_to_payment: 'Passer au paiement',
    order_validated: 'Commande validée',
    order_confirmation_title: 'Numéro de commande',
    order_number_short: 'N°{{ number }}',
    order_confirmation_content:
        "C'est en cuisine ! Votre commande vous sera apportée à table rapidement",
    close: 'Fermer',
    cart_error: 'Une erreur est survenue',
    cart_error_title: 'Oups...',
    cart_error_content:
        'Un de vos produits n’est plus disponible à la vente, nous l’avons retiré de votre panier',
    back_to_cart: 'Retour au panier',
    order_error: 'Une erreur est survenue',
    order_error_title: 'Echec de paiement',
    order_error_content: 'Une erreur est survenue lors du paiement, veuillez réessayer.',
    order_error_stripe: 'Temporairement indisponible',
    order_error_3ds: {
        title: 'Temps de chargement inhabituel, avez-vous eu une demande de validation de votre banque ?',
        button: "Je n'ai pas reçu de demande de validation",
        modal_title: 'Compatibilité 3D secure',
        modal_description:
            'Votre navigateur actuel ne supporte pas correctement le 3D secure. Vous n’avez pas été débité. Nous vous conseillons d’essayer sur un autre navigateur ou bien un autre moyen de paiement.',
        modal_button: "J'ai compris",
    },
    retry: 'Réessayer',
    terms_and_conditions: {
        title: 'Conditions générales de vente',
        title_short: 'CGV',
        accept: 'Je reconnais avoir pris connaissance des',
        price_title: 'Prix',
        price_content:
            "D'une manière générale les prix indiqués sur notre site ne sont pas contractuels, ils sont donnés à titre indicatif. Les prix sont toujours exprimés TTC sauf indication contraire. {{name, string}} se réserve le droit de modifier ses prix à tout moment mais s'engage à appliquer les tarifs en vigueur qui vous auront été indiqués au moment de votre commande.",
        payment_terms_title: 'Modalités de paiement',
        payment_terms_content:
            "Vous pouvez effectuer le règlement par carte bancaire : le paiement s'effectue sur le serveur bancaire sécurisé de notre partenaire Stripe. Ceci signifie qu'aucune information bancaire vous concernant ne transite via le site de {{name, string}}. Le paiement par carte bancaire est donc parfaitement sécurisé ; votre commande sera ainsi enregistrée et validée dès l'acceptation du paiement par la banque.",
        securing_title: 'Sécurisation',
        securing_content:
            "Les coordonnées de votre carte de crédit sont cryptées grâce au protocole SSL (Secure Socket Layer) et ne transitent jamais en clair sur le réseau. Le paiement est directement effectué auprès de la banque. {{name, string}} n'a en aucun cas accès à ces coordonnées et ne les garde pas sur ses serveurs. C'est pourquoi elles vous sont redemandées à chaque nouvelle transaction sur notre site.",
        online_order_title: 'Commande par internet',
        online_order_content:
            "La prise de commande sur le site internet {{name, string}} suppose votre inscription préalable en vue de la création d'un compte client {{name, string}}.",
        registration_title: '1-Inscription au site internet {{name, string}}',
        registration_content:
            "Lors de votre inscription au site internet {{name, string}}, vous devez choisir un identifiant associé à une adresse de courrier électronique valide et un mot de passe. Il vous appartient de garder ces informations strictement confidentielles et de ne pas les communiquer à des tiers, afin d'éviter autant que possible tout risque d'intrusion dans votre compte client et la passation de commande à votre insu par des personnes non autorisées. {{name, string}} ne saurait être responsable de toute utilisation de votre compte client par un tiers qui aurait eu accès à votre identifiant et à votre mot de passe, que ce soit à votre initiative ou par votre négligence. Lors de votre inscription au site internet {{name, string}}, vous devez également fournir une adresse de livraison valide située dans nos zones de livraison. L'adresse de livraison que vous indiquez lors de votre inscription doit être aussi précise que possible (numéro de bâtiment, d'étage, digicode, etc.), afin de permettre au livreur de vous apporter votre commande dans les meilleures conditions et les meilleurs délais. {{name, string}} ne pourra être tenue responsable en cas d'impossibilité de livraison due à des renseignements erronés ou incomplets. Une fois votre inscription effectuée, un e-mail récapitulatif vous sera envoyé à l'adresse de courrier électronique que vous aurez préalablement renseignée.",
        login_title: '2-Connexion au site internet {{name, string}}',
        login_content:
            "Avant chaque prise de commande, vous devrez vous connecter à l'espace client du site internet {{name, string}} en cliquant sur le lien 'Connexion' figurant sur la page d'accueil. En cas de perte ou d'oubli de votre mot de passe, vous avez la possibilité de réinitialiser ce dernier et d'en choisir un nouveau en cliquant sur le lien 'Mot de passe oublié' et en saisissant votre adresse électronique. vous recevrez alors un e-mail à l'adresse électronique indiquée, si elle est reconnue, vous permettant de choisir un nouveau mot de passe.",
        selection_title: '3-Sélection des plats et menus sur le site internet {{name, string}}',
        selection_content:
            "Les produits présentés sur la carte {{name, string}} le sont sous réserve des stocks disponibles dans le restaurant {{name, string}} dans le ressort duquel se trouve le lieu de livraison. En cas d'épuisement des stocks, votre restaurant {{name, string}} pourra, à sa seule discrétion, soit prendre contact avec vous par téléphone au numéro que vous aurez indiqué lors de votre inscription pour vous proposer d'opter pour un autre produit, soit réduire le montant de la facture du prix du produit indisponible. Cette réduction pourra également prendre la forme d'un avoir à valoir sur une prochaine commande.",
        validation_title: '4-Validation de la commande',
        validation_content:
            "Une fois la sélection des produits et menus effectuée, il vous est demandé de valider votre panier puis d'indiquer le mode de règlement choisi (espèces ou tickets restaurant lors de la livraison, carte bancaire après validation de la commande). Le site internet {{name, string}} accepte les paiements par Carte Bleue VISA et Master Card à travers Stripe, société de service de paiement sécurisé par internet. Lorsque cette opération est effectuée, votre commande est enregistrée automatiquement et devient ferme et définitive, conformément aux dispositions légales excluant l'exercice du droit de rétractation en matière de vente à distance de produits périssables.",
        signature_title: '5-Signature électronique',
        signature_content:
            "Une fois la sélection des produits et menus effectuée, il vous est demandé de valider votre panier puis d'indiquer le mode de règlement choisi (espèces ou tickets restaurant lors de la livraison, carte bancaire après validation de la commande). Le site internet {{name, string}} accepte les paiements par Carte Bleue VISA et Master Card à travers Stripe, société de service de paiement sécurisé par internet. Lorsque cette opération est effectuée, votre commande est enregistrée automatiquement et devient ferme et définitive, conformément aux dispositions légales excluant l'exercice du droit de rétractation en matière de vente à distance de produits périssables.",
        delivery_title: 'Livraison',
        delivery_content:
            "Dans un souci constant de recherche de qualité, {{name, string}} veille au respect des délais de livraison. Il appartient au Client de vérifier l'état de la commande à la livraison et d'informer dans les 24 heures le service clients du Mandataire des éventuelles réclamations qu'il désire porter à la connaissance du Mandataire. Les livreurs n’ont pas de monnaie sur eux, merci de faire l’appoint.",
    },
    header_table_number: 'Vous êtes à la table {{tableNumber, string}}',
    validate: 'Valider',
    comment: 'Commentaire',
    add_comment: 'Ajouter un commentaire',
    get_my_receipt_by_email: 'Obtenir mon reçu par email',
    enter_email: 'Renseignez votre adresse email',
    send: 'Envoyer',
    email_sent: 'Email envoyé',
    invalid_email: 'Adresse email invalide',
    order_detail: 'Récapitulatif',
    email: 'Email',
    mandatory: 'Obligatoire',
    included_options_one: '1 option incluse.',
    included_options_other: '{{maxChoice, number}} options incluses.',
    pick_number_max: 'Choisissez-en {{maxChoice, number}} max.',
    pick_number_min: 'Choisissez-en {{minChoice, number}} min.',
    save: 'Enregistrer',
    customization_required_one: '{{count}} personnalisation requise',
    customization_required_other: '{{count}} personnalisations requises',
    locate_kiosks: 'Localiser les kiosques',
    ticket_status_collected: 'Récupérée',
    ticket_status_cancelled: 'Annulée',
    ticket_status_waiting: 'En attente',
    ticket_status_processing: 'En préparation',
    ticket_status_ready: 'Prête',
    modal_product_error: {
        title_oops: 'Oups...',
        body_generic_product_name: 'Ce produit',
        body_not_available_one:
            '<strong>{{productsName}}</strong> est victime de son succès, ce produit n’est plus disponible pour le moment',
        body_not_available_other:
            '<strong>{{productsName}}</strong> sont victimes de leur succès, ces produits ne sont plus disponibles pour le moment',
        body_removed_product:
            'Un de vos produits n’est plus disponible à la vente, nous l’avons retiré de votre panier.',
        button_confirm: 'OK',
    },
    cross_selling: {
        title: 'Cross Selling',
    },
    home: 'Accueil',
    home_page: {
        initial: {
            surtitle: 'Bienvenue chez',
            title: '{{title}} 🎉',
            content:
                'Faites votre choix ci-dessous et laissez-nous vous emmener dans un voyage culinaire inoubliable.',
            cta1: 'Commander',
            cta2: 'not used',
        },
        copied: 'Copié',
        alcohol_banner: {
            title: 'Interdiction de vente de boissons alcooliques aux mineurs de moins de 18 ans.',
            subtitle: `La preuve de majorité de l'acheteur est exigée au moment de la vente en ligne.`,
            code: 'CODE DE LA SANTÉ PUBLIQUE, ART L3342 ET L3353-3',
        },
        consumption_modes: {
            surtitle: 'Bienvenue chez',
            title: '{{title}} 🎉',
            cta1: 'Sur place',
            cta2: 'À emporter',
        },
        table_number: ' (Table {{tableId}})',
    },
    incognito: 'Navigation privée',
    incognito_page: {
        warning: '⚠️ Attention ⚠️ ',
        title: 'Navigation privée',
        copied: 'Copié',
        step_1: {
            content:
                '<strong>Commande impossible</strong> car vous êtes en <strong>navigation privée</strong>.',
            cta: 'Comment faire',
        },
        step_2: {
            content:
                '<strong>1. Copiez</strong> le lien<br><strong>2.</strong> Ouvrez votre navigateur <strong>hors navigation privée</strong><br> <strong>3. Collez</strong> le lien dans la barre de navigation',
            cta: 'Copier le lien',
        },
    },
    login: 'Connexion',
    login_page: {
        title: 'Connectez-vous',
        content: 'Veuillez saisir vos identifiants de connexion afin de débuter votre commande',
        inputs: {
            email: {
                label: 'Adresse e-mail',
                placeholder: 'john.dott@innovorder.fr',
                bad_format: 'Le format de l’e-mail est invalide',
            },
            password: {
                label: 'Mot de passe',
                placeholder: 'Saisir un mot de passe',
            },
            submit: 'Connexion',
        },
        login_failed: 'Vos identifiants sont invalides',
        uncatched_error: 'Connexion impossible. Veuillez réessayer ultérieurement',
    },
    modal_logged_out: {
        title: 'Déconnexion réussie',
        body: "Vous allez être redirigé vers la page d'accueil",
        submit_button: 'OK',
    },
    account: 'Connexion',
    account_button: 'Mon compte',
    account_page: {
        hello: 'Bonjour, {{ userName }}',
        waiting_page: 'En construction, mais du nouveau arrive bientôt !',
        logout: 'Déconnexion',
        ewallet_balance: 'Mon solde : <strong>{{ customerBalance }}</strong>',
        ewallet_authorized_balance:
            'Mon budget disponible : <strong>{{ customerBalance }}</strong>',
        ewallet_reload: 'Recharger mon badge e-wallet',
    },
    modal_order_notification: {
        title: 'Commandes prêtes',
        i_understand: "J'ai compris",
        order_ready:
            'Votre commande <strong>{{ restaurantName, string }}</strong> est <strong>prête</strong>, vous pouvez aller la récupérer',
    },
    modal_order_status: {
        title_waiting: 'Commande en attente',
        title_processing: 'Commande en préparation',
        title_cancelled: 'Commande annulée',
        title_ready: 'Commande prête',
        title_collected: 'Commande récupérée',
        i_understand: "J'ai compris",
        order_waiting:
            'Votre commande <strong>{{ restaurantName, string }}</strong> est <strong>en attente</strong>',
        order_processing:
            'Votre commande <strong>{{ restaurantName, string }}</strong> est <strong>en cours de préparation</strong>',
        order_cancelled:
            'Votre commande <strong>{{ restaurantName, string }}</strong> est <strong>annulée</strong>',
        order_ready:
            'Votre commande <strong>{{ restaurantName, string }}</strong> est <strong>prête</strong>, vous pouvez aller la récupérer',
        order_collected:
            'Votre commande <strong>{{ restaurantName, string }}</strong> a été <strong>récupérée</strong>',
    },
    modal_no_payment_method: {
        title: 'Oups...',
        content: "Aucun moyen de paiement n'est configuré sur ce restaurant",
        button: 'Retour',
    },
};

export default translationFr;

// TODO: when file as 100 trad, put some nesting. https://github.com/InnovOrder/monorepo/pull/8036#discussion_r952438270
